.page-title {
    background-color: $color-white-0;
    .avt-container-1200 {
        background-color: #fafafa;
    }
    .image {
        max-width: 120px;
        img {
            max-width: 100%;
            height: auto;
            border: 1px solid rgba(0,0,0,0.1);
        }
    }
}
.product-comparison {
    .avt-container-1200 {
        position: relative;
        background-color: $color-white-0;
        .control-wrapper-prev, .control-wrapper-next {
            position: absolute;
            top: 0;
            width: 30px;
            height: 100%;
            z-index: 2;
            button {
                border: none;
                background: none;
                width: 44px;
                height: 44px;
                padding: 250px 0;
                position: sticky;
                top: 31vh;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
                &:disabled {
                    opacity: 0.3;
                    cursor: auto;
                    pointer-events: none;
                }
                svg {
                    fill: #6C4A6B;
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .control-wrapper-prev {
            left: 0;
            @media (min-width: $breakpoint-tablet-min) {

            }
            @media (min-width: $breakpoint-desktop-large-min) {
                left: 250px;
            }
            
        }
        .control-wrapper-next {
            right: 20px;
        }
    }
    .compare-ext-link {
        .product-link {
            &.btn {
                display: inline-block;
                margin-top: 0;
                background: $color-yellow;
                color: $color-white;
                padding: 5px 10px;
                i {
                    margin-left: 4px;
                }
            }
        }
    }
}
.compare-menu {
    width: 100%;
    .compare-title {
        font-size: 37px;
        line-height: 47px;
        text-align: right;
        color: $color-violet;
        font-weight: bold;
    }
    .compare-links-back {
        
        @media (min-width: $breakpoint-tablet-min) {
            padding-top: 20px;
            text-align: right;
        }
    }
    .compare-link-back {
        font-size: 20px;
        line-height: 24px;
        color: $color-violet;
        text-decoration: underline;
        text-align: right;
        display: inline-block;
        padding-top: 15px;
        @media (min-width: $breakpoint-mobile-min) {
            padding-left: 30px;
        }
        i {
            font-size: 24px;
        }
    }
}
.compare-plus {
    display: block;
    width: 100%;
    height: 300px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: $breakpoint-mobile-min) {
        width: 220px;
        height: 220px;
    }
    @media (min-width: $breakpoint-tablet-min) {
        width: 136.66px;
        height: 136.66px;
    }
    @media (min-width: $breakpoint-desktop-min) {
        width: 219.98px;
        height: 219.98px;
    }
    @media (min-width: $breakpoint-desktop-large-min) {
        width: 269.98px;
        height: 269.98px;
    }
    &:before {
        content: '';
        display: block;
        width: 40px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: -5px;
        background: #D8D8D8;
    }
    &:after {
        content: '';
        display: block;
        width: 10px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: -20px;
        background: #D8D8D8;
    }
}
