.category-box {
    .image {
        border: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
        img {
            max-width: 100%;
            height: auto;
            display: block;
            transform: scale(1);
            transition: all .3s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .description {
        h2 {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
        }
    }
}
