.product-box {
    .image {
        border: 1px solid rgba(0,0,0,0.1);
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
            display: block;
            transform: scale(1);
            transition: all .3s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .description, .product-info {
        .name {
            font-size: 20px;
            margin-top: 15px;
            font-weight: 700;
            color: $color-font-second;
        }
        .sizes {
            .size {
                font-size: 15px;
                display: block;
            }
        }
    }
    .product-link {
        display: none;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 15px;
        font-weight: 700;
        color: $color-font-second;
        &.btn {
            display: inline-block;
            background: #fead00;
            color: #fff;
            padding: 5px 10px;
        }
    }
    .product-info {
        .name {
            line-height: 25px;
        }
    }
    .add-link {
        display: block;
        border: 1px solid $color-violet;
        font-size: 15px;
        color: $color-font-second;
        line-height: 33px;
        height: 35px;
        .compare-btn-ico {
            background-color: $color-violet;
            display: inline-flex;
            height: 33px;
            width: 31px;
            color: $color-white-0;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            vertical-align: top;
        }
        .compare-btn-text {
            @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                display: none;
            }
        }
    }
}

.product-box.slide {
    .image {
        margin-bottom: 15px;
    }
    .product-info {
        .product-description {
            margin-bottom: 15px;
            font-size: 15px;
        }
    }
}

.category-product-list {
    .product-box {
        .image {
            cursor: pointer;
        }
    }
}
