$color-gray: #5D5D5D;
$color-violet: #6C4A6B;
$color-white-0: #FFF;
$color-white: #fff;
$color-black-0: #000;
$color-black-dark: #17181A;
$color-gray-dark: #ababab;
$color-gray-light: #dcdcdc;
$color-yellow: #fead00;

$color-font-main: $color-gray;
$color-font-second: $color-violet;
$color-font-additional: #f2f2f2;
$bg-main: $color-black-0;



/*Breakpointy */
$breakpoint-extra-mobile-max:     575.98px;
$breakpoint-mobile-min:           576px;
$breakpoint-mobile-max:           767.98px;
$breakpoint-tablet-min:           768px;
$breakpoint-tablet-max:           1024.98px;
$breakpoint-desktop-min:          1025px;
$breakpoint-desktop-max:          1199.98px;
$breakpoint-desktop-large-min:    1200px;
$breakpoint-desktop-large-max:    1439.98px;
$breakpoint-desktop-xxl-min:      1440px;
