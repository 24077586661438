body, html {
    font-family: 'Lato', sans-serif;
}

@font-face {
    font-family: 'LatoBlack';
    src: url('https://cdn.avt.pl/fonts/LatoBlack.eot');
    src: url('https://cdn.avt.pl/fonts/LatoBlack.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoBlack.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoBlack.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoBlack.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoBlack.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LatoLight';
    src: url('https://cdn.avt.pl/fonts/LatoLight.eot');
    src: url('https://cdn.avt.pl/fonts/LatoLight.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoLight.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// FontAwesome
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &-plus::before            { content: "\f067"; }
    &-minus::before           { content: "\f068"; }
    &-eye::before             { content: "\f06e"; }
    &-external-link::before   { content: "\f08e"; }
    &-search::before          { content: "\f002"; }
    &-sort::before            { content: "\f0dc"; }
    &-caret-up::before        { content: "\f0d8"; }
    &-caret-down::before      { content: "\f0d7"; }
    &-times::before           { content: "\f00d"; }



    // Social media
    &-google-plus::before     { content: "\f0d5"; }
    &-facebook::before        { content: "\f09a"; }
    &-facebook-square::before { content: "\f082"; }
    &-instagram::before       { content: "\f16d"; }
    &-pinterest::before       { content: "\f0d2"; }
    &-twitter::before         { content: "\f099"; }
    &-youtube::before         { content: "\f167"; }
    &-angle-right::before     { content: "\f105"; }
    &-angle-left::before      { content: "\f104"; }
}
