.avt-arrow {
    position: fixed;
    z-index: 99;
    bottom: 30px;
    right: unset;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    cursor: pointer;
    transition: .4s;
    background: rgba(255, 255, 255, 0.6);
    display: none;
    
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        width: 20px;
        height: 20px;
        border: 3px solid rgba(0, 0, 0, 0.4);
        border-style: solid solid none none;
        border-radius: 20% 0;
        transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
        transition: .4s;
    }
    
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 3px;
        height: 20px;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50% 50% 0 0;
        background: rgba(0, 0, 0, 0.4);
        transition: .4s;
    }
    
    &:hover {
        background: rgba(255, 255, 255, 0.8);
        border-color: rgba(0, 0, 0, 0.8);
    }
    &:hover:before {
        border-color: rgba(0, 0, 0, 0.8);
    }
    &:hover:after {
        background: rgba(0, 0, 0, 0.8);
    }

    &-wrapper {
        position: absolute;
        right: 65px;
        bottom: 0;
    }
}

.position-relative { position: relative !important; }
