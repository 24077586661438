html, body {
    color: $color-font-main;
}

a, a:hover, a:active, a:link {
    color: $color-font-main;
    text-decoration: none;
}

p, ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}
