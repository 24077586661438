.popup-mask {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
    &.show {
        display: block;
    }
}
.popup-product-wrap {
    padding: 20px;
    position: absolute;
    display: block;
    z-index: 10;
    overflow: hidden;
    @media (min-width: $breakpoint-desktop-min) {
        width: 902px;
    }
}

.popup-product-desc {
    background: #fff;
    padding: 25px 25px;
    font-weight: normal;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    .row {
        max-height: 80vh;
        .desc {
            overflow: hidden;
            max-height: 80vh;
            .product-box {
                overflow: hidden;
                height: 100%;
                .scroll-wrapper {
                    height: 30vh;
                    @media (min-width: $breakpoint-mobile-min) {
                        height: 100%;
                    }
                }
            }
        }
    }
    .description {
        h3.name {
            margin-top: 0px;
            padding-right: 40px;
        }
    }
    .image {
        img {
            &:hover {
                transform: scale(1);
            }
        }
    }
}

.popup-close {
    position: absolute;
    right: 37px;
    top: 37px;
    width: 30px;
    height: 30px;
    background: url('../../images/icons-home.png');
    background-position: 144px 39px;
    background-size: 261px 59px;
    transform: rotate(45deg);
    cursor: pointer;
    z-index: 11;
}

.popup-product-desc {
    .popup-close {
        top: 47px;
        right: 67px;
    }
}

.popup-mask {
    .field-desc-wrapper {
        display: block;
        z-index: 4;
        .field-desc-close {
            z-index: 11;
        }
    }
}

/*************** SCROLLBAR BASE CSS ***************/
 
.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
}
 
.scroll-wrapper > .scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
    padding-right: 20px;
}
 
.scroll-wrapper > .scroll-content::-webkit-scrollbar {
    height: 0;
    width: 0;
}
 
.scroll-element {
    display: none;
}
.scroll-element, .scroll-element div {
    box-sizing: content-box;
}
 
.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}
 
.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
    cursor: default;
}
 
.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;
}
.scroll-textarea > .scroll-content {
    overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
    border: none !important;
    box-sizing: border-box;
    height: 100% !important;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    outline: none;
    padding: 2px;
    position: relative !important;
    top: 0;
    width: 100% !important;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
}
 
 
 
 
/*************** SIMPLE CUSTOM SCROLLBAR ***************/
 
.scrollbar-custom > .scroll-element,
.scrollbar-custom > .scroll-element div
{
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
}
 
.scrollbar-custom > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}
 
.scrollbar-custom > .scroll-element.scroll-x {
    bottom: 2px;
    height: 8px;
    left: 0;
    width: 100%;
}
 
.scrollbar-custom > .scroll-element.scroll-y {
    height: 100%;
    right: 2px;
    top: 0;
    width: 8px;
}
 
.scrollbar-custom > .scroll-element .scroll-element_outer {
    overflow: hidden;
}
 
.scrollbar-custom > .scroll-element .scroll-element_outer,
.scrollbar-custom > .scroll-element .scroll-element_track,
.scrollbar-custom > .scroll-element .scroll-bar {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
 
.scrollbar-custom > .scroll-element .scroll-element_track,
.scrollbar-custom > .scroll-element .scroll-bar {
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: 0.4;
}
 
.scrollbar-custom > .scroll-element .scroll-element_track { background-color: #e0e0e0; }
.scrollbar-custom > .scroll-element .scroll-bar { background-color: #c2c2c2; }
.scrollbar-custom > .scroll-element:hover .scroll-bar { background-color: #919191; }
.scrollbar-custom > .scroll-element.scroll-draggable .scroll-bar { background-color: #919191; }
 
 
/* update scrollbar offset if both scrolls are visible */
 
.scrollbar-custom > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track { left: -12px; }
.scrollbar-custom > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track { top: -12px; }
 
 
.scrollbar-custom > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -12px; }
.scrollbar-custom > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -12px; }