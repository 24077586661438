header {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    background: #F7FDFD;
    background: linear-gradient(to bottom, rgba(247, 253, 253, 1) 85%, rgba(247, 253, 253, 0) 100%);
    padding-bottom: 10px;
    @media (min-width: $breakpoint-tablet-min) {
        background: linear-gradient(to bottom, rgba(247, 253, 253, 1) 75%, rgba(247, 253, 253, 0) 100%);
        padding-bottom: 0;
    } 
    .header-wrap {
        @media(min-width: $breakpoint-tablet-min) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex-basis: auto;
        }
    }
    .logo-box {
        margin: 10px 0 5px;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 15px 0;
        }
        a {
            display: flex;
            justify-content: center;
        }
        .logo-text {
            color: $color-font-second;
            padding-left: 8px;
            text-transform: uppercase;
            line-height: 30px;
            @media (min-width: $breakpoint-desktop-min) {
                width: 100%;
                font-size: 28px;
                line-height: 48px;
                padding-left: 15px;
            }
            span {
                font-family: 'LatoLight', 'Lato', sans-serif;
                font-size: 17px;
                padding-left: 8px;
                @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                    padding-left: 0;
                }
                @media (min-width: $breakpoint-desktop-min) {
                    font-size: 27px;
                    padding-left: 15px;
                }
            }
            strong {
                font-family: 'LatoBlack', 'Lato', sans-serif;
                font-size: 17px;
                display: inline-block;
                @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                    display: block;
                    line-height: 13px;
                    padding-top: 3px;
                }
                @media (min-width: $breakpoint-desktop-min) {
                    font-size: 27px;
                }
            }
        }
        .logo-image img {
            width: 50px;
            @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                width: 70px;
            }
            @media (min-width: $breakpoint-desktop-min) {
                width: 100%;
            }
        }
    }
    .product-info-box {
        font-size: 16px;
        color: $color-font-second;
        text-align: center;
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        @media(min-width: $breakpoint-tablet-min) {
            margin-bottom: 0;
            width: auto;
            margin-bottom: 15px;
        }
        .product-button {
            border-radius: 4px;
            border: 1px solid $color-font-second;
            width: 290px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2px 2px 2px 10px;
            .count {
                font-weight: 700;
            }
            .open-button {
                text-decoration: none;
                background-color: $color-violet;
                color: $color-white-0;
                display: inline-block;
                padding: 0 8px;
                border-radius: 4px;
                font-weight: 700;
            }
        }
        &.count-disabled {
            .open-button {
                pointer-events: none;
                cursor: default;
                background-color: rgba( $color-violet, .5 );
            }
        }
        &.count-home {
            opacity: 0.6;
        }
        .compare-minus {
            display: none;
            position: absolute;
            margin: 0 auto;
            width: 0;
            height: 0;
            background: $color-violet;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .other-links {
        @media(min-width: $breakpoint-desktop-min) {
            display: flex;
        }
        a {
            color: $color-font-second;
            font-size: 16px;
            font-weight: 400;
        }
        .info {
            display: none;
            margin-left: 15px;
            @media(min-width: $breakpoint-desktop-min) {
                display: block;
            }
        }
    }
}
