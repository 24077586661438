footer {
    line-height: 1;
    .avt-footer-top {
        text-align: center;
        background-color: $bg-main;
        padding-top: 40px;
        @media(min-width: $breakpoint-tablet-min) {
            text-align: left;
            padding-bottom: 40px;
        }
        .avt-footer-avt {
            padding-bottom: 32px;
            border-bottom: 2px solid rgba(0,0,0,0.2);
            @media(min-width: $breakpoint-tablet-min) {
                padding-bottom: 0;
                position: relative;
                border-bottom: none;
            }
            &:after{
                @media(min-width: $breakpoint-tablet-min) {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 2px;
                    background: rgba(0,0,0,0.2);
                    top: 8px;
                    right: 40px;
                    bottom: 8px;
                }
            }
        }
        .avt-footer-header {
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 40px;
            @media(min-width: $breakpoint-tablet-min) {
                margin-top: 8px;
                margin-bottom: 0;
            }
        }
        .avt-footer-address,
        .avt-footer-NIP-KRS,
        .avt-footer-mail,
        .avt-footer-tel {
            color: $color-font-main;
            font-size: 15px;
            line-height: 25px;
            display: block;
            margin: 0;
            text-decoration: none;
        }
        .avt-footer-mail {
            margin-top: 16px;
            color: $color-font-additional;
        }

    }

    .avt-footer-bottom {
        background-color: $color-black-dark;
        padding-top: 64px;
        padding-bottom: 32px;

        .title {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            color: #fff;
        }

        ul.menu-right, .ul-list ul {
            list-style: none;
            margin-top: 30px;
            margin-bottom: 0;
            padding: 0;
            overflow: hidden;

            li {
                + li {
                    margin-top: 10px;
                }

                a {
                    display: inline;
                    padding: 0;
                    color: #ababab;
                    font-size: 14px;
                    //.transition(color);

                    &:hover, &:focus {
                        color: #d4d4d5;
                        text-decoration: none;
                        background-color: transparent;
                    }
                }
            }
        }

        .ul-list ul {
            padding-left: 15px;
            padding-right: 15px;
            flex: none;
            max-width: none;
            @media(min-width: $breakpoint-tablet-min) {
                width: 33.33333333%;
                float: left;
            }

            &.col-2, &.col-3 {
                margin-top: 10px;
                @media(min-width: $breakpoint-tablet-min) {
                    margin-top: 30px;
                }
            }

            li {
                span {
                    display: block;
                    margin: 30px 0;
                    font-size: 14px;
                    line-height: 1.4;
                    text-transform: uppercase;
                    color: #ffffff;
                }

                &:first-child {
                    span {
                        margin-top: 0;
                    }
                }
            }
        }

        .col-xs-12.order-md-0 {
            margin-top: 30px;
            @media(min-width: $breakpoint-tablet-min) {
                margin-top: 0;
            }
        }

    }

    .footer-line {
        border-top: 3px solid #494a4e;
        padding: 31px 0 0;
        margin-top: 85px;
        @media(min-width: $breakpoint-tablet-min) {
            padding: 23px 0 0;
        }
        @media(min-width: $breakpoint-desktop-min) {
            margin-top: 105px;
        }
    }

    .footer-logo {
        display: block;
        width: 95px;
        height: 35px;
        margin: 0 auto;
    }

    // Our Sites
    .our-sites { margin-top: 30px; }

    .page-group {
        margin-bottom: 30px;

        .group-title {
            margin-bottom: 30px;
            color: $color-white;
            font-size: 14px;
            line-height: 1.4;
            text-transform: uppercase;
        }

        .group-list {
            padding: 0;
            list-style: none;
            list-style-type: none;
        }

        .group-item {
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-child { padding-top: 0px; }
        }

        .group-link {
            color: $color-gray-dark;
            font-size: 14px;

            &:hover {
                color: lighten($color-gray-dark, 16%);
                text-decoration: none;
            }
        }
    }

    &.reklama-page {
        padding: 64px 0 32px;
        background: #111111;
        color: #aeaeae;

        .footer-line {
            border-top: 2px splid #565656;
            margin-top: 42px;
        }

        .footer-logo {
            @media(min-width: $breakpoint-desktop-min) {
                margin: 0;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            @media(min-width: $breakpoint-tablet-min) {
                display: flex;
                justify-content: space-between;
            }
            @media(min-width: $breakpoint-desktop-min) {
                justify-content: flex-start;
            }

            li {
                text-align: center;
                + li {
                    margin-top: 20px;
                }
                @media(min-width: $breakpoint-tablet-min) {
                    text-align: left;
                    + li {
                        margin-top: 0;
                    }
                }
                @media(min-width: $breakpoint-desktop-min) {
                    margin: 0 35px 0 0;
                }

                a {
                    color: #aeaeae;
                    transition: color 0.25s ease 0s;
                    text-decoration: none;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}
