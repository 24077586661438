.new-products-list-wrapper {
    .new-products-list {
        display: none;
        &.active {
            display: block;
        }
        .position-relative {
            position: relative;
        }
    }
}

.new-products {
    .avt-container-1200 {
        background-color: $color-white-0;
    }
    .new-products-category {
        .new-products-header {
            font-size: 25px;
            font-weight: 700;
            color: $color-font-second;
        }
        ul {
            li {
                font-size: 18px;
                font-weight: 400;
                padding: 5px 0;
                position: relative;
                cursor: pointer;
                padding-right: 50px;
                &.active {
                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        height: 1px;
                        width: 40px;
                        right: 0px;
                        top:0;
                        bottom:0;
                        margin: auto;
                        background-color: $color-violet;
                    }
                }
            }
        }
    }
    .new-products-list {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            display: block;
            top:0;
            bottom:0;
            height: 100%;
            left: -30px;
            width: 1px;
            background-color: $color-violet;
        }
        .product-box {
            .add-link {
                display: block;
            }
        }
        .last-added-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
