.page-title {
    .title-wrap {
        display: flex;
        flex-wrap: wrap;
        .text {
            padding-left: 15px;
            .title {
                color: $color-font-second;
                font-size: 36px;
                font-weight: 700;
            }
            .category {
                font-size: 16px;
                font-weight: 400;
                color: $color-font-main;
            }
        }
    }
}
