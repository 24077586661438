.properties-box {
    font-size: 16px;
    line-height: 24px;
    li {
        @media(max-width: $breakpoint-mobile-max) {
            margin-bottom: 28px;
        }
        @media(min-width: $breakpoint-tablet-min) {
            line-height: 17px;
            padding: 8px 0;
        }
    }
}
