#products-search {
    .search-title-top {
        font-size: 26px;
        line-height: 32px;
        color: $color-gray;
        padding-bottom: 15px;
    }
    .search-checkbox {
        label {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 6px;
            line-height: 20px;
            cursor: pointer;
            font-size: 15px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }
            .checker {
                position: absolute;
                top: 0;
                left: 0;
                height: 19px;
                width: 19px;
                background-color: $color-white;
                border: 1px solid $color-gray-dark;
            }
            input:checked ~ .checker {
                background-color: $color-violet;
                border-color: $color-violet;
            }
            .checker:after {
                content: "";
                position: absolute;
                display: none;
            }
            
            input:checked ~ .checker:after {
                display: block;
            }
            
            .checker:after {
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid $color-white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    .search-title {
        padding-top: 27px;
        margin-bottom: 10px;
        color: $color-violet;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        padding-bottom: 10px;
        padding-right: 30px;
        position: relative;
        display: block;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid $color-gray-light;
        }
        .search-title-toggle-wrap {
            position: absolute;
            right: 0;
            top: 25px;
            cursor: pointer;
            padding: 8px 2px 8px 10px;
            transition: all 0.3s;
            .search-title-toggle {
                border-left: 1px solid $color-gray-dark;
                border-bottom: 1px solid $color-gray-dark;
                transform: rotate(135deg);
                height: 18px;
                width: 18px;
                transition: all 0.3s;
            }
        }
    }
    .field-wrap {
        overflow: hidden;
        max-height: 165px;
        display: block;
        width: 100%;
    }
    fieldset {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 165px;
    }
    .control-group {
        &.hide {
            .search-title {
                .search-title-toggle-wrap {
                    padding: 0px 2px 20px 10px;
                    .search-title-toggle {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .search-btn {
        font-size: 15px;
        background: $color-violet;
        color: $color-white;
        padding: 5px 0;
        border: none;
        outline: none;
        cursor: pointer;
        width: 100%;
        display: block;
        text-align: center;
    }
    select {
        font-size: 15px;
        color: $color-gray;
        padding: 9px 2px;
        width: 100%;
        display: block;
        border: 1px solid #dcdcdc;
    }
    input {
        width: 100%;
        padding: 6px 5px;
        border: 1px solid #dcdcdc;
    }
    .search-title {
        .search-title-wrap {
            position: relative;
            display: inline-block;
            margin-right: 40px;
            .field-desc-show {
                right: -30px;
            }
        }
    }
}