.background-container {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../images/background.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}
