#error {
    header, #menuTop {
        display: none;
    }
    #content {
        padding-top: 15px;
    }
    #errorboxoutline {
        width: 100%;
        text-align: center;
        #techinfo {
            table {
                margin: 0 auto;
            }
        }
    }
    #mod-finder-searchform {
        width: 100%;
        max-width: 530px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.12);
        height: auto;
        padding-left: 15px;
        border: 1px solid #a2a2a2;
        font-weight: 400;
        position: relative;
        margin: 0 auto;
        .wrap {
            width: 100px;
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            &:after {
                content: "Szukaj";
                display: block;
                height: 40px;
                position: absolute;
                top: 0;
                line-height: 40px;
                font-size: 18px;
                font-weight: 700;
                right: 10px;
                z-index: 0;
            }
            &:before {
                content: "\f002";
                display: block;
                position: absolute;
                height: 40px;
                font-family: FontAwesome;
                font-size: 20px;
                line-height: 40px;
                color: #a2a2a2;
                left: 0;
                z-index: 0;
            }
        }
        .submit {
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 9;
            position: absolute;
            top:0;
            left: 0;
            cursor: pointer;
            border: none;
            &:active, &:focus {
                outline: none;
            }
        }
    }
    #mod-finder-searchword {
        font-size: 20px;
        height: 40px;
        margin-bottom: 0;
        width: calc(100% - 105px);
        box-sizing: border-box;
        border: none;
        @media (max-width: $breakpoint-mobile-max) {
            font-size: 15px;
        }
        &:active, &:focus {
            outline: none;
        }
    }
    #outline {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }
}

.error {
    font-size: 14px;
    font-weight: bold;
    color: #a2a2a2;
    height: 75vh;
    display: flex;
    align-items: center;
    @media (max-width: $breakpoint-mobile-max) {
        height: 80vh;
        padding-top: 50px;
    }
    .code {
        font-size: 64px;
        font-weight: 900;
        opacity: 0.5;
    }
    .text {
        font-size: 36px;
        font-weight: 300;
        margin-bottom: 5px;
        @media (max-width: $breakpoint-mobile-max) {
            font-size: 25px;
            line-height: 27px;
        }
    }
    .szukaj {
        width: 100%;
        @media (max-width: $breakpoint-mobile-max) {
            margin-bottom: 20px;
        }
    }
    .info {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 45px;
        @media (max-width: $breakpoint-mobile-max) {
            margin-bottom: 20px;
        }
        a {
            font-weight: 700;
        }
    }
    .background-box {
        background: url("../images/error_bg.jpg");
        height: 250px;
        width: 250px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 30px;
        @media (max-width: $breakpoint-mobile-max) {
            width: 180px;
            height: 180px;
        }
    }
    .error-logo {
        position: absolute;;
        top: 15px;
        img {
            max-width: 100%;
            height: auto;
            display: block;
        
        }
    }
    .upss {
        color: #e2ad07;
        font-size: 46px;
        margin-bottom: 20px;
    }
}