.category-main {
    .avt-container-1200 {
        background-color: $color-white-0;
    }
    .category-info {
        @media(min-width: $breakpoint-tablet-min) {
            display: flex;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid rgba(0,0,0,0.2);
        }
        .header-wrap {
            @media(min-width: $breakpoint-tablet-min) {
                width: 70%;
            }
            @media(min-width: $breakpoint-desktop-min) {
                width: 45%;
            }
        }
        .image {
            display: none;
            @media(min-width: $breakpoint-tablet-min) {
                display: block;
                padding-right: 15px;
                min-width: 131px;
            }
            img {
                max-width: 100%;
                height: auto;
                display: block;
                border: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
        .page-header {
            font-size: 30px;
            font-weight: 700;
            color: $color-violet;
            margin-bottom: 0;
            letter-spacing: -0.03em;
            line-height: 33px;
            padding-top: 5px;
            @media (min-width: $breakpoint-desktop-min) and (max-width: $breakpoint-desktop-max) {
                font-size: 24px;
            }
        }
        .category-name {
            font-size: 20px;
            font-weight: 400;
        }
    }
    .category-select {
        height: 35px;
        border: 1px solid rgba(0,0,0,0.3);
        width: 100%;
        line-height: 33px;
        transition: all .4s;
        margin-bottom: 15px;
        position: relative;
        @media(min-width: $breakpoint-tablet-min) {
            align-self: flex-end;
            margin-bottom: 0;
            max-width: 300px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            display: flex;
            height: auto;
            line-height: normal;
            max-width: none;
            border: none;
            overflow: hidden;
        }
        &.open {
            height: 100%;
            overflow: initial;
            i {
                transform: rotate(180deg);
            }
        }
        .select, .list {
            padding-left: 5px;
        }
        .list {
            @media(max-width: $breakpoint-tablet-max) {
                position: absolute;
                left: -1px;
                right: -1px;
                z-index: 81;
                top: 33px;
                background: $color-white;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-top: 0;
                display: none;
            }
            @media(min-width: $breakpoint-desktop-min) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                max-height: 130px;
                transition: max-height .8s;
                overflow: hidden;
            }
            li {
                letter-spacing: -0.03em;
                @media(min-width: $breakpoint-desktop-min) {
                    width: calc(100%/3);
                    padding: 5px 0;
                    font-size: 15px;
                    a:hover {
                        text-decoration: underline;
                    }
                }
                &:nth-child(3n+2), &:nth-child(2) {
                    @media(min-width: $breakpoint-desktop-min) {
                        padding-left: 7px;
                        padding-right: 7px;
                    }
                }
                &.hide {
                    @media(min-width: $breakpoint-desktop-min) {
                        display: none;
                    }
                }
                &.list-more {
                    display: none;
                    color: $color-violet;
                    font-weight: 700;
                    padding-right: 7px;
                    cursor: pointer;
                    @media(min-width: $breakpoint-desktop-min) {
                        display: list-item;
                    }
                }
                &.active {
                    color: $color-violet;
                    font-weight: 700;
                    a {
                        color: $color-violet;
                        font-weight: 700;
                    }
                }
                
            }
            &.list-show {
                max-height: 400px;
            }
        }
        .select {
            position: relative;
            @media(min-width: $breakpoint-desktop-min) {
                display: none;
            }
            i {
                top: 0;
                bottom: 0;
                margin: auto;
                right: 15px;
                position: absolute;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transition: all .4s;
            }
        }
    }
    .category-sort {
        margin-bottom: 15px;
        @media(min-width: $breakpoint-tablet-min) {
            margin-bottom: 0;
            width: 78%;
            display: flex;
            padding-right: 15px;
        }
        @media(min-width:  $breakpoint-desktop-min) {
            width: 100%;
            justify-content: flex-end;
            padding-right: 0;
        }
        a {
            font-size: 15px;
            font-weight: 700;
            color: $color-font-second;
            margin-bottom: 10px;
            display: block;
            @media(min-width: $breakpoint-tablet-min) {
                margin-bottom: 0;
                position: relative;
                width: 50%;
            }
            @media(min-width:  $breakpoint-desktop-min) {
                width: auto;
                padding-right: 30px;
            }
            .value {
                font-weight: 400;
                @media(min-width:  $breakpoint-desktop-min) {
                    display: inline;
                }
            }
            i {
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
    .filter-box {
        align-items: center;
        @media(min-width: $breakpoint-tablet-min) {
            display: flex;
        }
        .filter-buttons {
            margin-bottom: 15px;
            display: flex;
            justify-content: space-around;
            cursor: pointer;
            @media(min-width: $breakpoint-desktop-min) {
                margin-bottom: 0;
                width: 0%;
                justify-content: flex-end;
                padding-left: 15px;
                display: none;
            }


            .btn {
                color: $color-font-second;
                padding: 5px 20px;
                border: 1px solid $color-violet;
                display: block;
                @media(min-width: $breakpoint-tablet-min) {
                    margin-left: 15px;
                }
            }
            .filter-btn {
                .filter-open {
                    transition: all .4s;
                    transform: rotate(0deg);
                }
                &.opened {
                    .filter-open {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
.product-list-wrap {
    .avt-container-1200 {
        background-color: $color-white-0;
    }
    .filter-list {
        background-color: #ffffff;
        display: none;
        @media (max-width: $breakpoint-tablet-max) {
            position: absolute;
            left: 0;
            right: 0;
            top: 20px;
            z-index: 2;
            padding: 0 15px 15px 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }
        @media(min-width: $breakpoint-desktop-min) {
            height: 100%;
        }
    }
    .category-product-list {
        background-color: $color-white-0;
        .name {
            cursor: pointer;
        }
        .description {
            overflow: hidden;
            position: relative;
            box-shadow: inset 0px -124px 50px -21px rgba(255,255,255,1);
            .mask-wrap {
                width: 100%;
                height: 30px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
            .mask {
                background: linear-gradient(to bottom, rgba(255,255,255, 0) 0%,#ffffff 100%);
                width: 100%;
                height: 30px;
                position: relative;
                display: none;
                cursor: pointer;
                z-index: 1;
            }
            &.short {
                height: 285px;
                .mask {
                    display: block;
                }
            }
        }
        .no-products-row {
            text-align: center;
            .no-products {
                font-size: 20px;
                font-weight: 700;
                padding-top: 30px;
                padding-bottom: 30px;
                @media (min-width: $breakpoint-desktop-min) {
                    font-size: 25px;
                    padding-top: 200px;
                }
            }
        }
    }
}
