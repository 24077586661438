/* Vendors */

@import "vendors/bootstrap";
@import "vendors/flickity";
@import "vendors/swiper";

/* Abstracts */

@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";

/* Base */

@import "base/reset";
@import "base/typography";


/* Layout */

@import "layout/grid";
@import "layout/header";
@import "layout/navigation";
@import "layout/sidebar";
@import "layout/forms";
@import "layout/footer";

/* Pages */

@import "pages/index";
@import "pages/category";
@import "pages/comparison";
@import "pages/error";

/* Components */
@import "components/containers";
@import "components/background-container";
@import "components/category-box";
@import "components/buttons";
@import "components/new-products";
@import "components/product-box";
@import "components/product-slider";
@import "components/properties-box";
@import "components/slider-kiosk";
@import "components/page-title";
@import "components/search";
@import "components/product-popup";

/* Themes */
