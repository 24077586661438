.category-btn-wrap {
    position: absolute;
    bottom: 0;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include background-gradient();
}
a.all-category-button {
    border: 1px solid $color-violet;
    font-size: 22px;
    font-weight: 700;
    color: $color-font-second;
    height: 43px;
    line-height: 43px;
    width: 100%;
    text-align: center;
    max-width: 390px;
    display: block;
    float: right;
}

.btn-shutter-out-vert {
    position: relative;
    transition-duration: 0.3s;
    transition-property: color;
    transform: perspective(1px) translateZ(0);
    vertical-align: middle;
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $color-violet;
        transform: scaleY(0);
        transform-origin: 50%;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
    }
    &:hover {
        color: $color-white;
        &:before {
            transform: scaleY(1);
        }
    }
}
