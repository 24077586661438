#product-slider {
    max-width: 500px;
    margin: 0 auto;
    @media(min-width: $breakpoint-desktop-min) {
        max-width: 750px;
    }
    @media(min-width: $breakpoint-desktop-large-min){
        max-width: 900px;
        margin-right: 30px;
    }
    .carousel-cell {
        padding: 15px;
        width: 100%;
        @media(min-width: $breakpoint-mobile-min) {
            width: 50%;
        }
        @media(min-width: $breakpoint-tablet-min) {
            width: 33.3333%;
        }
    }

    .flickity-prev-next-button{
        border: none;
        background: none;
        display: none;
        svg {
            fill: $color-violet;
        }
        &.next {
            right: -15px;
            @media(min-width: $breakpoint-mobile-min){
                right: -30px;
            }
        }
        &.previous {
            left: -15px;
            @media(min-width: $breakpoint-mobile-min){
                left: -30px;
            }
        }
    }
}
#properties-slider {
    max-width: 500px;
    margin: 0 auto;
    @media(min-width: $breakpoint-desktop-min) {
        max-width: 750px;
    }
    @media(min-width: $breakpoint-desktop-large-min){
        max-width: 900px;
        margin-right: 30px;
    }
    .static-banner {
        top:-15px;
        position: absolute;
        width: 100%;
        z-index: 2;
        @media(min-width: $breakpoint-tablet-min) {
            position: absolute;
            left: -140px;
            top: 15px;
            width: calc(100% + 140px);
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            left: -280px;
            width: calc(100% + 280px);
        }
        .properties-list {
            font-size: 16px;
            line-height: 27px;
            padding: 0 15px;
            font-weight: 900;
            @media(min-width: $breakpoint-tablet-min) {
                line-height: 23px;
            }
            li {
                @media(max-width: $breakpoint-mobile-max) {
                    margin-bottom: 28px;
                }
                position: relative;
                border-bottom: 1px solid rgba(0,0,0,0.2);
                &:last-child {
                    @media(min-width: $breakpoint-tablet-min) {
                        border-bottom: none;

                    }
                }
                .properties-element {
                    @media(min-width: $breakpoint-tablet-min) {
                        max-width: 160px;
                        padding: 6px 0;
                    }
                    @media(min-width: $breakpoint-desktop-large-min) {
                        max-width: 180px;
                    }
                    span {
                        position: relative;
                        padding-right: 30px;
                        display: inline-block;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: -0.02em;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .carousel-cell {
        padding: 15px;
        width: 100%;
        @media(min-width: $breakpoint-mobile-min) {
            width: 50%;
        }
        @media(min-width: $breakpoint-tablet-min) {
            width: 33.3333%;
        }
    }

}

.field-desc-show {
    position: absolute;
    top: -2px;
    right: 0;
    width: 21px;
    height: 21px;
    background-image: url('../../images/info-ico.svg');
    background-size: cover;
}

.field-desc-wrapper {
    display: none;
    position: absolute;
    z-index: 10;
    right: 35px;
    width: 378px;
    background: $color-white;
    padding: 25px 42px;
    font-weight: normal;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0 rgba(0,0,0,0.16);
    transition: opacity 0.2s visibility 0.2s;
    @media (max-width: $breakpoint-mobile-max) {
        right: '';
        left: 0;
        display: none;
    }
    &:before {
        content: ' ';
        width: 34px;
        height: 34px;
        background: $color-white;
        display: block;
        position: absolute;
        left: -18px;
        top: calc(50% - 9px);
        border-top: 1px solid #d8d8d8;
        border-left: 1px solid #d8d8d8;
        z-index: 1;
        transform: rotate(-45deg);
        @media (max-width: $breakpoint-mobile-max) {
            display: none;
        }
    }
    .field-desc-title {
        font-size: 25px;
        margin-bottom: 18px;
    }
    .field-desc {
        font-size: 15px;
        line-height: 22px;
        @media (max-width: $breakpoint-mobile-max) {
            max-height: 300px;
            overflow-y: auto;
        }  
    }
    .field-desc-close {
        position: absolute;
        right: 30px;
        top: 23px;
        width: 30px;
        height: 30px;
        background: url('../../images/icons-home.png');
        background-position: 144px 39px;
        background-size: 261px 59px;
        transform: rotate(45deg);
        display: none;
        @media (max-width: $breakpoint-mobile-max) {
            display: block;
        }
    }
}