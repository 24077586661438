.avt-container-1200 {
    width: 100%;
    padding: 0 15px;
    max-width: 1230px;
    margin: 0 auto;
}
.avt-container-1400 {
    width: 100%;
    padding: 0 15px;
    max-width: 1430px;
    margin: 0 auto;
}
