.home-top-section {
    @media (min-width: $breakpoint-tablet-min) {
        text-align: right;
    }
    .section-title {
        font-family: 'LatoBlack', 'Lato', sans-serif;
        // font-size: 79px;
        // line-height: 82px;
        font-size: 46px;
        line-height: 50px;
        @media (min-width: $breakpoint-tablet-min) {
            display: inline-block;
            border-right: 1px solid $color-gray;
            padding-right: 21px;
            vertical-align: middle;
        }
    }
    .section-subtitle {
        font-size: 27px;
        vertical-align: middle;
        @media (min-width: $breakpoint-tablet-min) {
            display: inline-block;
            padding-top: 8px;
            padding-left: 14px;
        }
    }
    .home-ico {
        width: 120px;
        height: 120px;
        border: 1px solid $color-gray;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        text-align: center;
        background-image: url('../../images/icons-home.png');
        &.ico-search {
            background-position: -8px -11px;
        }
        &.ico-plus {
            background-position: -211px -11px;
        }
        &.ico-sort {
            background-position: -409px -11px;
        }
        i {
            font-size: 50px;
            line-height: 120px;
            color: $color-violet;
        }
    }
    .ico-title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        padding-top: 10px;
        color: $color-violet;
    }
}

.products-category {
    .avt-container-1200 {
        background-color: $color-white-0;
    }
    .category-header {
        font-size: 25px;
        font-weight: 700;
        padding: 30px 0 20px;
        color: $color-font-second;
    }
    .category-list {
        position: relative;
        // height: 410px;
        overflow: hidden;
        transition: all .4s;
        &.open {
            height: 100%;
        }
    }
    &-title {
        font-size: 17px;
        font-weight: 600;
        margin: 0 15px 20px 15px;
        border-bottom: 3px solid #6c4a6b;
        color: #6c4a6b;
        text-transform: uppercase;
        max-width: 100%;
        width: 100%;
        text-align: right;
        padding-bottom: 6px;
    }
}
